/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import CompaniesService from 'api/companies/CompaniesService';
import { CompanyDto } from 'api/companies/models/CompanyDto';
import UsersService from 'api/users/UsersService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './CompanyScreen.module.scss';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import CustomFile from 'common/models/CustomFile';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';

type Props = {
};

const CompanyScreen: React.FC<Props> = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasCompaniesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_COMPANIES_WRITE']);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<CompanyDto | null>(null);
    const [medias, setMedias] = useState<CustomFile[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState<CompanyDto>();
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        addressLine: '',
        zipCode: '',
        city: '',
        taxNumber: '',
        email: '',
    });

    const getData = async () => {
        try {
            let result: any = { id: '', name: '', addressLine: '', zipCode: '', city: '', taxNumber: '', email: '' };
            if (id) {
                Loading.show();
                result = await CompaniesService.getById(id);
                Loading.hide();
            }
            setItem(result);
            setFormData(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get company information', error);
            toast.error(t('messages.error_load_info'));
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/company/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/companies');
        }
    }
    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }

        try {
            await CompaniesService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            toast.success(t('messages.record_delete_success'));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete company', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    const showRemoveItemDialog = async (item: CompanyDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onAddMedias = (medias: CustomFile[]) => {
        setMedias(medias);
    }

    const onRemoveMedia = (mediaId: string) => {
        const filteredMedia = [...medias.filter(x => x.id !== mediaId)];
        setMedias(filteredMedia);
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: CompanyDto = {
                id: formData.id,
                name: formData.name,
                addressLine: formData.addressLine,
                zipCode: formData.zipCode,
                city: formData.city,
                taxNumber: formData.taxNumber,
                email: formData.email,
            };
            if (model && model.id) {
                await CompaniesService.update(model, medias)
                navigateTo('details', id);
            } else if (model) {
                const id = await CompaniesService.create(model, medias)
                navigateTo('details', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error?.response?.status === 409) {
                toast(t('messages.company_already_exists'));
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the company with id: ${form.id}`, error);
                toast.error(t('messages.record_save_error'));
            }
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('companies.title')}>
            <Breadcrumb>
                <Breadcrumb.Item>{t('menu.configuration')}</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => { navigateTo() }}>
                    {t('companies.title')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{t(`common.${type}`)}</Breadcrumb.Item>
            </Breadcrumb>
            <Form onSubmit={onSubmit} noValidate validated={validated}>
                <Row>
                    <Col xs={10} >
                        <Col xs={12} >
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>{t('companies.name')}{!isDetails ? '*' : ''}</Form.Label>
                                <Form.Control required name="name" type="text" defaultValue={item?.name} readOnly={isDetails} onChange={handleChange} placeholder={t('companies.name')} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} >
                            <Form.Group className="mb-3" controlId="address">
                                <Form.Label>{t('companies.address')}</Form.Label>
                                <Form.Control name="addressLine" type="text" defaultValue={item?.addressLine} readOnly={isDetails} onChange={handleChange} placeholder={t('companies.address')} />
                            </Form.Group>
                        </Col>
                    </Col>
                    <Col xs={2}>
                        <Form.Label>{t('companies.logo')}</Form.Label>
                        <MediaSelector
                            isMulti={false}
                            isDetails={isDetails}
                            onAddMedias={onAddMedias}
                            onRemoveMedia={onRemoveMedia}
                            initialMedias={item?.medias ?? []} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="zip_code">
                            <Form.Label>{t('companies.zip_code')}</Form.Label>
                            <Form.Control name="zipCode" type="text" defaultValue={item?.zipCode} readOnly={isDetails} onChange={handleChange} placeholder={t('companies.zip_code')} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Group className="mb-3" controlId="city">
                            <Form.Label>{t('companies.city')}</Form.Label>
                            <Form.Control name="city" type="text" defaultValue={item?.city} readOnly={isDetails} onChange={handleChange} placeholder={t('companies.city')} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="tax_number">
                            <Form.Label>{t('companies.tax_number')}</Form.Label>
                            <Form.Control name="taxNumber" type="text" defaultValue={item?.taxNumber} readOnly={isDetails} onChange={handleChange} placeholder={t('companies.tax_number')} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>{t('companies.email')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Control required name="email" defaultValue={item?.email} type="text" readOnly={isDetails} onChange={handleChange} placeholder={t('companies.email')} />
                        </Form.Group>
                    </Col>
                </Row>
                <div className={styles.buttonsContainer}>
                    <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                        {t('common.cancel')}
                    </Button>
                    {isDetails && hasCompaniesWritePolicy &&
                        <Button variant="danger" type="button" className={styles.button} onClick={() => showRemoveItemDialog(item as CompanyDto)} >
                            {t('common.delete')}
                        </Button>
                    }
                    {isDetails && hasCompaniesWritePolicy &&
                        <Button variant="warning" type="button" className={styles.button} onClick={() => { navigateTo('edit', item?.id); }}>
                            {t('common.edit')}
                        </Button>
                    }
                    {!isDetails && hasCompaniesWritePolicy &&
                        <Button variant="primary" type="submit" className={styles.button}>
                            {t('common.save')}
                        </Button>
                    }
                </div>
            </Form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: item?.name ?? '' })} />
        </ScreenTitle>
    );
};

export default CompanyScreen;
