import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedLayout from './common/layouts/authenticatedLayout/AuthenticatedLayout';
import AuthorizeRoute from './common/routes/AuthorizeRoute';
import { ApplicationPaths, LoginActions, LogoutActions } from './Config';
import ForbiddenScreen from './screens/errors/ForbiddenScreen';
import NotFoundScreen from './screens/errors/NotFoundScreen';
import LoginScreen from './screens/auth/LoginScreen';
import LogoutScreen from './screens/auth/LogoutScreen';
import HomeScreen from 'screens/home/HomeScreen';
import CompaniesScreen from 'screens/companies/CompaniesScreen';
import PolicyRoute from 'common/routes/PolicyRoute';
import CompanyScreen from 'screens/company/CompanyScreen';
import ApplicationsScreen from 'screens/applications/ApplicationsScreen';
import ApplicationScreen from 'screens/application/ApplicationScreen';
import AccountScreen from 'screens/account/AccountScreen';

const HomeRedirection = () => {
    return <Navigate replace={true} to={ApplicationPaths.Home} />;
};

const Router = () => {
    return (

        <BrowserRouter>
            <Routes>
                <Route path="/404" element={<NotFoundScreen />} />
                <Route path="/403" element={<ForbiddenScreen />} />
                <Route path={ApplicationPaths.Login} element={<LoginScreen action={LoginActions.Login} />} />
                <Route path={ApplicationPaths.Reset} element={<LoginScreen action={LoginActions.Reset} />} />
                <Route path={ApplicationPaths.LoginFailed} element={<LoginScreen action={LoginActions.LoginFailed} />} />
                <Route path={ApplicationPaths.LoginCallback} element={<LoginScreen action={LoginActions.LoginCallback} />} />
                <Route path={ApplicationPaths.LogOut} element={<LogoutScreen action={LogoutActions.Logout} />} />
                <Route path={ApplicationPaths.LogOutCallback} element={<LogoutScreen action={LogoutActions.LogoutCallback} />} />
                <Route path='/*' element={
                    <AuthorizeRoute>
                        <AuthenticatedLayout>
                            <Routes>
                                <Route path="/" element={<HomeRedirection />} />
                                <Route path="/home" element={<HomeScreen />} />
                                <Route path="/account/:type" element={<AccountScreen />} />
                                <Route path={'/companies'} element={
                                    <PolicyRoute policies={['SETTINGUP_COMPANIES_READ']}><CompaniesScreen /></PolicyRoute>}
                                />
                                <Route path={'/company/:type/:id?'} element={
                                    <PolicyRoute policies={['SETTINGUP_COMPANIES_READ', 'SETTINGUP_COMPANIES_WRITE']}><CompanyScreen /></PolicyRoute>}
                                />
                                <Route path={'/applications'} element={
                                    <PolicyRoute policies={['SETTINGUP_APPLICATIONS_READ']}><ApplicationsScreen /></PolicyRoute>}
                                />
                                <Route path={'/application/:type/:id?'} element={
                                    <PolicyRoute policies={['SETTINGUP_APPLICATIONS_READ', 'SETTINGUP_APPLICATIONS_WRITE']}><ApplicationScreen /></PolicyRoute>}
                                />
                                <Route path="*" element={<Navigate replace={true} to="/404" />} />
                            </Routes>
                        </AuthenticatedLayout>
                    </AuthorizeRoute>
                } />

                <Route path="*" element={<Navigate replace={true} to="/404" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
