import React, { useCallback, useMemo } from 'react';
import styles from './AuthenticatedLayout.module.scss';
import SideBar, { SideBarItem } from '../sidebar/Sidebar';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import NavBar from '../navbar/NavBar';
import { faHome, faLandmark, faComputer, faFileLines } from '@fortawesome/free-solid-svg-icons';
interface Props {
    children: React.ReactNode
}

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const validateItem = useCallback((item: SideBarItem) => {
        const type = item.type ?? 'AND';
        const policies = item.policies ?? [];
        const userPolicies = loggedUser?.policies ?? [];
        return item.policies.length > 0 ? UsersService.hasPolicies(userPolicies, policies, type) : true;
    }, [loggedUser]);

    const sidebarItems: SideBarItem[] = useMemo(() => {
        const items: SideBarItem[] = [
            {
                text: t('menu.home'),
                url: '/home',
                icon: faHome,
                policies: [],
                type: 'OR'
            },
            {
                text: t('menu.configuration'),
                url: '/#',
                policies: [
                    'SETTINGUP_COMPANIES_READ',
                    'SETTINGUP_COMPANIES_WRITE',
                    'SETTINGUP_APPLICATIONS_READ',
                    'SETTINGUP_APPLICATIONS_WRITE'
                ],
                type: 'OR',
                items: [{
                    text: t('menu.companies'),
                    url: '/companies',
                    icon: faLandmark,
                    policies: [
                        'SETTINGUP_COMPANIES_READ', 'SETTINGUP_COMPANIES_WRITE'
                    ],
                    type: 'OR',
                    items: []
                },
                {
                    text: t('menu.applications'),
                    url: '/applications',
                    icon: faComputer,
                    policies: [
                        'SETTINGUP_APPLICATIONS_READ', 'SETTINGUP_APPLICATIONS_WRITE'
                    ],
                    type: 'OR',
                    items: []
                }]
            }
        ];
        return items.filter(item => validateItem(item))
        .map(item => { item.items = item && item.items ? item.items.filter(i => validateItem(i)) : undefined; return item; });
    }, [loggedUser, validateItem]);

    return (
        <>
            <div className="main-wrapper">
                <NavBar />
                <SideBar items={sidebarItems} key="sidebar" />
                <main className="main-container container-fluid">
                    {children}
                </main>
            </div>
        </>
    );
};

export default AuthenticatedLayout;
